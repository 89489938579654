@media screen and (max-width:640px) {
    .tippy-box[data-theme~='tomato'] {
        display: none;
    }
}

.tippy-box[data-theme~='tomato'] {
    background-color: #dc2626;
  }

.tippy-box[data-theme~='tomato'][data-placement^='top'] > .tippy-arrow::before {
border-top-color: #dc2626;
}
.tippy-box[data-theme~='tomato'][data-placement^='bottom'] > .tippy-arrow::before {
border-bottom-color: #dc2626;
}
.tippy-box[data-theme~='tomato'][data-placement^='left'] > .tippy-arrow::before {
border-left-color: #dc2626;
}
.tippy-box[data-theme~='tomato'][data-placement^='right'] > .tippy-arrow::before {
border-right-color: #dc2626;
}

/* .tippy-box[data-theme~='upgrade'] {
    background-color: rgb(243 244 246);;
  }

.tippy-box[data-theme~='upgrade'][data-placement^='top'] > .tippy-arrow::before {
border-top-color: rgb(243 244 246);
}
.tippy-box[data-theme~='upgrade'][data-placement^='bottom'] > .tippy-arrow::before {
border-bottom-color: rgb(243 244 246);
}
.tippy-box[data-theme~='upgrade'][data-placement^='left'] > .tippy-arrow::before {
border-left-color: rgb(243 244 246);
}
.tippy-box[data-theme~='upgrade'][data-placement^='right'] > .tippy-arrow::before {
border-right-color: rgb(243 244 246);
} */